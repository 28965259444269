// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import 'app/shared/modules/photo-gallery/StreetViewError.scss';
import Row from 'app/shared/core/Row';
import Container from 'app/shared/core/Container';

const StyledBoldDiv = styled.div`
    font-weight: 700;
`;
const StreetViewError = () => {
    return (
        <Container className="StreetViewError">
            <Row size="md">
                <StyledBoldDiv>Uh oh.</StyledBoldDiv>
                Sorry, street view is not available on this property. Nothing to see here.
            </Row>
        </Container>
    );
};

export default StreetViewError;
