/* eslint-disable no-nested-ternary */
import { css, cx } from 'app/styled-system/css';

export const PhotoGalleryButtonCSS = css({
    width: '100%',
    height: '100%'
});

export const PhotoGalleryImageCSS = css({
    objectFit: 'cover',
    width: '100%',
    height: '100%'
});
