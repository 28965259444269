// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Head from 'react-helmet';
import { useSelector } from 'react-redux';

import isoConfig from 'config/isoConfig';
import {
    listingUtils_getListingImgAltText,
    listingUtils_getListingMetaDescription
} from 'app/shared/utils/listingUtils';
import controller from './controller';

const domain = 'https://hotpads.com';

const ListingHead = () => {
    const listing = useSelector((state) => state.currentListingDetails.currentListing);

    if (!listing) {
        return null;
    }

    const { uriV2 } = listing;
    const description = listingUtils_getListingMetaDescription(listing);
    const listingImgUrl = listing.previewPhoto.url;
    const title = controller.getPageTitle(listing);
    const imgAltText = listingUtils_getListingImgAltText({
        photoCaption: listing.previewPhoto.caption,
        nameOrAddress: listing.displayName
    });
    const links = [
        {
            rel: 'alternate',
            href: 'android-app://com.hotpads.mobile/https/hotpads.com' + uriV2
        }
    ];
    const metas = [
        { name: 'description', content: description },
        { name: 'keywords', content: listing.details.metaKeywords || '' },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:site', content: '@hotpads' },
        { property: 'twitter:creator', content: '@hotpads' },
        { property: 'twitter:image:alt', content: imgAltText },
        { property: 'fb:app_id', content: isoConfig.fbid },
        { property: 'og:title', content: title || '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: listingImgUrl || '' },
        { property: 'og:image:secure_url', content: listingImgUrl || '' },
        { property: 'og:description', content: description || '' },
        { property: 'og:site_name', content: 'HotPads' },
        { property: 'og:locale', content: 'en_US' }
    ];

    if (!listing.active) {
        metas.push({
            name: 'apple-itunes-app',
            content: `app-id=345957475, app-argument=${domain + uriV2}`
        });
    }

    if (uriV2) {
        links.push({
            rel: 'canonical',
            href: domain + uriV2
        });
        metas.push({
            property: 'og:url',
            content: domain + uriV2
        });
    } else {
        metas.push({
            name: 'robots',
            content: 'noindex'
        });
    }

    if (controller.hdpShouldNoIndex(listing)) {
        metas.push({
            name: 'robots',
            content: 'noindex'
        });
    }

    return <Head link={links} meta={metas} title={title} />;
};

export default ListingHead;
