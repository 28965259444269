// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Container from 'app/shared/core/Container';
import HdpPhotoGalleryV2 from 'app/shared/modules/hdp/galleryV2/HdpPhotoGalleryV2';
import ListingNavBar from 'app/shared/modules/hdp/ListingNavBar';
import Row from 'app/shared/core/Row';
import Section from 'app/shared/modules/Section';
import SkeletonTitle from 'app/shared/modules/skeleton/SkeletonTitle';
import 'app/shared/modules/skeleton/SkeletonHdp.scss';

class SkeletonHdp extends React.Component {
    static propTypes = {
        previewListing: PropTypes.object
    };

    static defaultProps = {
        previewListing: null
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.scrollToTop();
    }

    scrollToTop() {
        const sidebar = document.getElementById('SplitMapTemplate-right-sidebar');
        sidebar.scrollTop = 0;
    }

    render() {
        const { indexInList, listOfHdpUrls, previewListing } = this.props;

        return (
            <>
                <ListingNavBar currentListingIndex={indexInList} listOfHdpUrls={listOfHdpUrls} />
                <div className="SkeletonHdp">
                    {/*
                    pass props here so that the ListingNavBar can
                    differentiate new HDP view (same state as can go forward/backwards)
                    and initial loading / bad state
                */}
                    {previewListing && !previewListing.building ? (
                        <HdpPhotoGalleryV2 listing={previewListing} />
                    ) : (
                        <Fragment>
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                            <SkeletonTitle />
                        </Fragment>
                    )}
                    <Container>
                        <Section>
                            <Row>
                                <SkeletonTitle />
                            </Row>
                            <Row>
                                <SkeletonTitle width="40%" />
                            </Row>
                            <Row>
                                <SkeletonTitle width="85%" />
                            </Row>
                            <Row>
                                <SkeletonTitle width="95%" />
                            </Row>
                        </Section>
                    </Container>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobile: state.app.device.screenWidth === 'sm',
    previewListing: state.listings.listingGroups.previewListing
});

export default connect(mapStateToProps)(SkeletonHdp);
