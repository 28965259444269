// @ts-nocheck
/* eslint-enable */
import React from 'react';
import omit from 'lodash/omit';

import './style.scss';
import { PhotoGalleryImageCSS, PhotoGalleryButtonCSS } from 'app/shared/modules/photo-gallery/PhotoGalleryImage/styles';

class PhotoGalleryImage extends React.Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.observer = this.props.photo.intersectionObserver;
    }

    componentDidMount() {
        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.observer.unobserve(this.ref.current);
    }

    render() {
        const { index, onClick, photo, height, width } = this.props;

        const photoProps = omit(photo, ['intersectionObserver']);

        return (
            <button className={PhotoGalleryButtonCSS} onClick={(e) => onClick(e, { index, photo })}>
                <img className={PhotoGalleryImageCSS} ref={this.ref} {...photoProps} />
            </button>
        );
    }
}

export default PhotoGalleryImage;
