import isNull from 'lodash/isNull';

const canGoForward = function(index: number | null, listLength: number) {
    return !isNull(index) && index < listLength - 1;
};
const canGoBackward = function(index: number | null) {
    return !isNull(index) && index !== 0;
};
const isProbablyLoading = function(index: number, listLength: number) {
    return index === 0 && listLength === 0;
};

export { canGoForward, canGoBackward, isProbablyLoading };
