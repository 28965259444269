// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';

// Hooks
import useContactButton from './useContactButton';

// Misc / utils
import IconContact from 'images/icons/contact-white.svg';
import { ContactFAB, StyledContactIcon } from './styles';

const FloatingActionContactButton = ({ contactButtonClass, onContactFabClick }) => {
    const [handleContactButtonClick] = useContactButton({ onClick: onContactFabClick });

    return (
        <ContactFAB
            contactButtonClass={contactButtonClass}
            btnType={'primary'}
            rounded
            size="lg"
            onClick={handleContactButtonClick}
        >
            <StyledContactIcon src={IconContact} height="20px" width="22px" alt="" />
        </ContactFAB>
    );
};

export default FloatingActionContactButton;
