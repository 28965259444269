// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import imageUtils from 'app/shared/utils/imageUtils';
import includes from 'lodash/includes';

const controller = {
    getPhotos: (listing, excludeLargePhotos = false) => {
        if (!listing) {
            return imageUtils.noImageFound();
        }

        let photos = [];

        if (listing.largePhotos && listing.largePhotos.length && !excludeLargePhotos) {
            photos = photos.concat(listing.largePhotos);
        } else if (listing.photos && listing.photos.length) {
            photos = photos.concat(listing.photos);
        } else if (listing && listing.previewPhoto.url) {
            let previewPhoto = listing.previewPhoto;
            if (includes(previewPhoto.url, 'thumb')) {
                previewPhoto.url = previewPhoto.url.replace(/thumb/, 'large');
            } else if (includes(previewPhoto.url, 'medium')) {
                previewPhoto.url = previewPhoto.url.replace(/medium/, 'large').replaceAll('500', '1200');
            }

            photos.push(previewPhoto);
        } else {
            photos.push(listing.previewPhoto);
        }

        // add floorplan photos to gallery
        photos = photos.concat(controller.getFloorplanPhotos(listing));

        if (photos[0] && photos[0].url) {
            return photos;
        } else {
            return imageUtils.noImageFound();
        }
    },
    getFloorplanPhotos: (listing) => {
        const { floorplans } = listing;
        let floorplanPhotos = [];

        forEach(floorplans, (fp = {}) => {
            const { largePhotos: fpPhotos = [] } = fp;
            forEach(fpPhotos, (photo) => {
                /**
                 *  must add modelId for floorplan fdp because index is unreliable
                 * (photo gallery shows floorplans with photos and not all floorplans have photos)
                 */
                const photoObjWithId = assign(photo, { modelId: fp.modelId });
                floorplanPhotos.push(photoObjWithId);
            });
        });

        if (floorplanPhotos[0] && floorplanPhotos[0].url) {
            return floorplanPhotos;
        } else {
            return [];
        }
    }
};

export default controller;
