import React from 'react';
import loadable from '@loadable/component';
import { GoogleMapComponentProps } from 'app/shared/modules/map/GoogleMap/component';

const GoogleMap = loadable(
    () => import(/* webpackChunkName: "GoogleMap.loadable" */ 'app/shared/modules/map/GoogleMap/component')
);

const LoadableGoogleMap = (props: GoogleMapComponentProps) => {
    return <GoogleMap {...props} />;
};

export default LoadableGoogleMap;
