// @ts-nocheck
/* eslint-enable */
import numberUtils from 'app/shared/utils/numberUtils';
import isNil from 'lodash/isNil';

const controller = {
    hdpShouldNoIndex(listing) {
        const isLand = listing.propertyType === 'land';
        if (isLand) {
            return true;
        } else {
            return false;
        }
    },
    getPageTitle(listing) {
        const { address, displayName, isApartmentBldg, pricing, unit } = listing;
        const { street, city, state, zip, hideStreet } = address;
        const noAddress = `${hideStreet ? '(Undisclosed Address)' : displayName}, ${city}, ${state} ${zip}`;
        const formattedAddress = hideStreet ? noAddress : `${street}, ${city}, ${state} ${zip}`;
        const formattedAddressCityOnly = `in ${city}`;
        const formattedAddressWithApt = hideStreet ? noAddress : `${street} Apt ${unit}, ${city}, ${state} ${zip}`;

        const regEx = /apartment/gi;
        let title = '';

        if (isApartmentBldg && displayName !== street) {
            const containsApartmentKeyword = displayName.match(regEx);
            const priceSummary = pricing && pricing.summary;
            const priceLowOrHigh = (priceSummary && priceSummary.priceLow) || (priceSummary && priceSummary.priceHigh);
            const tabTitlePrice =
                priceLowOrHigh !== 0 && !isNil(priceLowOrHigh)
                    ? ` - ${numberUtils.asCurrency(priceLowOrHigh)}+ per month`
                    : '';
            const appendApartmentKeyword = containsApartmentKeyword
                ? ` ${formattedAddressCityOnly}`
                : ` Apartments ${formattedAddressCityOnly}`;

            const formattedDisplayName = displayName + appendApartmentKeyword;

            if (formattedDisplayName.length <= 40) {
                title = formattedDisplayName + tabTitlePrice;
            } else {
                title = formattedDisplayName;
            }
        } else if (unit) {
            title = formattedAddressWithApt;
        } else {
            title = formattedAddress;
        }
        return (title += ' | HotPads');
    }
};

export default controller;
