interface WrappedPromise<T> {
    // eslint-disable-next-line no-unused-vars
    resolve: (value: T | PromiseLike<T>) => void;
    // eslint-disable-next-line no-unused-vars
    reject: (reason?: any) => void;
    promise: Promise<T>;
    then: Promise<T>['then'];
    catch: Promise<T>['catch'];
}

export const wrappedPromise = <T = any>(): WrappedPromise<T> => {
    let wrapper: Partial<WrappedPromise<T>> = {};
    const promise = new Promise<T>((resolve, reject) => {
        wrapper.resolve = resolve;
        wrapper.reject = reject;
    });

    wrapper.then = promise.then.bind(promise);
    wrapper.catch = promise.catch.bind(promise);
    wrapper.promise = promise;

    return wrapper as WrappedPromise<T>;
};