import noPhoto from 'images/noPhoto.png';
import Photo from 'app/shared/models/Photo';

const imageUtils = {
    noImageFound() {
        return [new Photo({ url: noPhoto, noPhoto: true })];
    }
};

export default imageUtils;
