// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { useYieldCallback } from '@zillow/yield-callback';

// Components
import * as S from 'app/shared/modules/hdp/galleryV2/HdpPhotoGalleryV2Styles';
import GalleryButtonGroupV2 from 'app/shared/modules/hdp/galleryV2/GalleryButtonGroupV2';
import ContactCallToAction from 'app/shared/modules/photo-gallery/ContactCallToAction';
import GoogleMap from 'app/shared/modules/map/GoogleMap';
import PhotoCarouselV2 from 'app/shared/modules/PhotoCarouselV2';
import PhotoGalleryPopupModal from 'app/shared/modules/photo-gallery/PhotoGalleryPopupModal';
import Tooltip from 'app/shared/modules/Tooltip';

// Misc / Utils
import noPhoto from 'images/noPhoto.png';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { iconForListing } from 'app/client/utils/map/gmapIcons';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import controller from './controller';
import Icon3DGlasses from 'images/icons/3d-glasses.svg';
import IconVerified from 'images/verified.svg';
import ImageLoader from 'app/shared/modules/photo-gallery/ImageLoader';
import {
    listingHasTourUrls,
    listingUtils_getListingImgAltText,
    listingUtils_getListingImgTitleText
} from 'app/shared/utils/listingUtils';
import Marker from 'app/client/google/Marker';
import queryUtils from 'app/shared/utils/queryUtils';
import StreetView from 'app/client/google/StreetView';
import { TrackHdpPhotoGalleryOpen } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const { bool, func, number, object } = PropTypes;
const VERIFIED_TEXT =
    'This listing comes to HotPads from a verified source, ' +
    "a known and established property manager or property manager's representative.";

const HdpPhotoGalleryV2 = ({
    listing,
    isMobile,
    excludeLargePhotos,
    screenWidth,
    instantTourForm,
    photoIndex,
    onClickContact,
    onClickOpenFdp
}) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState('gallery');
    const [showPhotoGalleryFull, setShowPhotoGalleryFull] = useState(false);
    const [statePhotoIdx, setStatePhotoIdx] = useState(photoIndex);
    const [photoGalleryFullEventFired, setPhotoGalleryFullEventFired] = useState(false);
    const photos = useMemo(() => controller.getPhotos(listing, excludeLargePhotos), [excludeLargePhotos, listing]);
    const [show3dToursIframe, setShow3dToursIframe] = useState(false);
    const [isLoadingAllPhotos, setIsLoadingAllPhotos] = useState(false);
    const { active: isListingActive, isAllowedToRetain } = listing;
    /**
     * Data should be hidden per MLS if the listing is:
     * -- active: false
     * -- isAllowedToRetain: false
     */
    const shouldBeHiddenIfDataCannotBeRetained = isListingActive === false && isAllowedToRetain === false;

    const handleOpenPhotoGalleryFull = useCallback(() => {
        if (!photoGalleryFullEventFired) {
            dispatch(
                analyticsEvent(gaEvents.HDP_OPEN_PHOTO_GALLERY_FULL, { newLaneEvent: TrackHdpPhotoGalleryOpen() })
            );
            setPhotoGalleryFullEventFired(true);
        }
        setShowPhotoGalleryFull(true);
    }, [dispatch, photoGalleryFullEventFired]);

    const yieldHandleSelectOption = useYieldCallback((clickedBtn) => {
        if (visible === 'gallery' && clickedBtn === 'gallery') {
            handleOpenPhotoGalleryFull();
            return;
        }

        dispatch(
            analyticsEvent(gaEvents.HDP_GALLERY_CONTROLS_CLICK, {
                label: clickedBtn
            })
        );

        setVisible(clickedBtn);
    });

    const renderStreetView = useCallback(() => {
        if (!listing || visible !== 'street') {
            return null;
        }

        return <StreetView lat={listing.geo.lat} lng={listing.geo.lon} />;
    }, [listing, visible]);

    const renderMap = useCallback(() => {
        if (!listing || visible !== 'map') {
            return null;
        }

        const icon = iconForListing(listing, listing.maloneLotIdEncoded);
        const position = {
            lat: listing.geo.lat,
            lng: listing.geo.lon
        };
        const ariaLabel = gmapUtils.getAccessibleLabel({
            listing,
            isPadOrBuildingUrl: true
        });
        const accessibleInstructions = `Details about this listing, including address and price, can be found in the Home Details section
        inside the element with id 'Hdp-listing-container'`;

        return (
            <div style={{ height: `100%` }}>
                <GoogleMap
                    lat={listing.geo.lat}
                    lon={listing.geo.lon}
                    zoom={14}
                    ariaLabel={ariaLabel}
                    accessibleInstructions={accessibleInstructions}
                >
                    <Marker name={listing.displayName} position={position} icon={icon} />
                </GoogleMap>
            </div>
        );
    }, [listing, visible]);

    const loadAllPhotos = useCallback(() => {
        if (!isLoadingAllPhotos) {
            setIsLoadingAllPhotos(true);
        }
    }, [isLoadingAllPhotos, setIsLoadingAllPhotos]);

    const handleOpen3dTours = useYieldCallback(() => {
        dispatch(analyticsEvent(gaEvents.HDP_PHOTOGALLERY_3DTOUR_CLICKED));
        setShow3dToursIframe(true);
    });

    // change due to click left/right in main photo gallery
    const handlePhotoCarouselChange = useYieldCallback((i) => {
        loadAllPhotos();
        setStatePhotoIdx(i);

        dispatch(analyticsEvent(gaEvents.HDP_ADDITIONAL_PHOTO_VIEW));
    });

    const renderGallery = () => {
        const photosToShow = isLoadingAllPhotos ? photos : photos.slice(0, 1);

        const isMediumOrSmallerScreenSize = !includes(['lg', 'xl'], screenWidth);
        if (!listing || visible !== 'gallery') {
            return null;
        }
        const { active, address, listedBy, trusted, details } = listing;
        const showContactInfo = details && details.isMls && isMobile;
        const show3dToursButton = listingHasTourUrls(listing);
        const maxIndex = photos.length;
        const ignoreKeypress = instantTourForm?.isOpen || showPhotoGalleryFull || show3dToursIframe;

        return (
            <S.HdpPhotoGalleryV2Content elementtiming="HdpPhotoGalleryV2-Carousel">
                {shouldBeHiddenIfDataCannotBeRetained ? (
                    <img
                        style={{ height: '100%', width: '100%', 'border-radius': '8px', 'object-fit': 'cover' }}
                        src={noPhoto}
                        alt="This expired listing does not have any photos"
                    />
                ) : (
                    <>
                        <PhotoCarouselV2
                            animateChange={isMediumOrSmallerScreenSize}
                            defaultIndex={statePhotoIdx}
                            ignoreKeypress={ignoreKeypress}
                            itemCountOffset={-1}
                            itemCountPosition="top-right"
                            loopable
                            maxIndex={maxIndex}
                            onChange={(i) => handlePhotoCarouselChange(i)}
                            trackScroll
                        >
                            {map(photosToShow, (photo, idx) => {
                                const displayName = listing.displayName;
                                const photoCaption = photo.caption;
                                const imgAltText = listingUtils_getListingImgAltText({
                                    photoCaption,
                                    nameOrAddress: displayName,
                                    isPrimaryPhoto: idx === 0
                                });
                                const imgTitleText = listingUtils_getListingImgTitleText({
                                    currentIndex: idx,
                                    maxIndex,
                                    nameOrAddress: displayName,
                                    addressDetails: address
                                });

                                return (
                                    <ImageLoader
                                        image={photo.url}
                                        key={photo.url}
                                        onClick={handleOpenPhotoGalleryFull}
                                        altText={imgAltText}
                                        titleText={imgTitleText}
                                        fetchPriority={idx === 0 ? 'high' : 'auto'}
                                    />
                                );
                            })}
                            {onClickContact && listing.active && (
                                <div key="HdpPhotoGallery-contact-overlay">
                                    <ContactCallToAction onCtaClick={onClickContact} />
                                </div>
                            )}
                        </PhotoCarouselV2>
                        {showContactInfo && (
                            <S.HdpPhotoGalleryV2ListingAgent>
                                {`${listedBy.contactName}: ${listedBy.contactPhone}`}
                            </S.HdpPhotoGalleryV2ListingAgent>
                        )}
                        <S.HdpPhotoGalleryV2TagWrapper>
                            {show3dToursButton && (
                                <S.HdpPhotoGalleryV2Tag rounded size="sm" btnType="default" onClick={handleOpen3dTours}>
                                    <S.HdpPhotoGalleryV2TagIcon alt="" src={Icon3DGlasses} />
                                    <span>3D Tour</span>
                                </S.HdpPhotoGalleryV2Tag>
                            )}
                            {active && trusted && (
                                <S.HdpPhotoGalleryV2Tag rounded size="sm" btnType="default">
                                    <Tooltip tip={VERIFIED_TEXT} tooltipPosition="below">
                                        <S.HdpPhotoGalleryV2TagIcon alt="" src={IconVerified} />
                                        <span>Verified</span>
                                    </Tooltip>
                                </S.HdpPhotoGalleryV2Tag>
                            )}
                        </S.HdpPhotoGalleryV2TagWrapper>
                    </>
                )}
            </S.HdpPhotoGalleryV2Content>
        );
    };

    const onPhotoGalleryPopupModalClose = useCallback(() => {
        setShowPhotoGalleryFull(false);
        setShow3dToursIframe(false);
    }, [setShowPhotoGalleryFull, setShow3dToursIframe]);

    if (!listing) {
        return null;
    }

    return (
        <S.HdpPhotoGalleryV2Wrapper>
            {renderGallery()}
            {renderMap()}
            {renderStreetView()}
            {shouldBeHiddenIfDataCannotBeRetained ? null : (
                <GalleryButtonGroupV2
                    isMobile={isMobile}
                    visible={visible}
                    numPhotos={photos.length}
                    onYieldSelectOption={yieldHandleSelectOption}
                />
            )}
            {(showPhotoGalleryFull || show3dToursIframe) && (
                <PhotoGalleryPopupModal
                    onClose={onPhotoGalleryPopupModalClose}
                    listing={listing}
                    photos={photos}
                    onClickContact={onClickContact}
                    onClickOpenFdp={onClickOpenFdp}
                    show3dToursIframe={show3dToursIframe}
                />
            )}
        </S.HdpPhotoGalleryV2Wrapper>
    );
};

HdpPhotoGalleryV2.propTypes = {
    isMobile: bool.isRequired,
    photoIndex: number.isRequired,
    listing: object,
    instantTourForm: object,
    onClickContact: func,
    onClickOpenFdp: func
};

HdpPhotoGalleryV2.defaultProps = {
    instantTourForm: {},
    onClickContact() { },
    onClickOpenFdp() { }
};

const mapStateToProps = (state, ownProps) => {
    return {
        isMobile: state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs',
        // use app.device.deviceType b.c isMobile/screenWidths is not immediately ready to use after SSR.
        excludeLargePhotos: state.app.device.deviceType !== 'desktop',
        photoIndex: Number(queryUtils.parse(ownProps.location.search).image) || 0,
        screenWidth: state.app.device.screenWidth,
        instantTourForm: state.currentListingDetails.instantTourForm
    };
};

export default withRouter(connect(mapStateToProps)(HdpPhotoGalleryV2));
