import noPhoto from 'images/noPhoto.png';

interface Photo {
    url: string;
    caption: string;
}

const controller = {
    checkPhotos(photos: Photo[]): Photo[] {
        return photos.length === 0 ? [{ url: noPhoto, caption: '' }] : photos;
    },
    getThumbnailStyle(thumbsTranslateX: number): React.CSSProperties {
        const translate3d = `translate3d(${thumbsTranslateX}px, 0, 0)`;
        return {
            WebkitTransform: translate3d,
            MozTransform: translate3d,
            msTransform: translate3d,
            OTransform: translate3d,
            transform: translate3d
        };
    }
};

export default controller;