import React from 'react';
import cx from 'classnames';

import './style.scss';

interface Props {
    children: React.ReactNode
    className: string
    showBottomBorder: boolean
}

const PhotoGalleryHeader = (props: Props) => {
    return (
        <div
            className={cx('PhotoGalleryHeader', props.className, {
                'PhotoGalleryHeader-bottom-border': props.showBottomBorder
            })}
        >
            {props.children}
        </div>
    );
};

export default PhotoGalleryHeader;
