import styled from 'styled-components';
import { variables } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';

export const hdpSharedCSSWidth = `
    width: 280px;
    box-sizing: border-box;

    @media (min-width: ${variables['contact-button-width-md'] + 20}px) {
        width: ${variables['contact-button-width-md']}px;
    }

    @media ${viewports['md-and-up']} {
        width: 399px;
    }

    @media ${viewports['xl-and-up-bigger-listing-cards']} {
        width: 274px;
    }
`;

export const hdpSharedCSSButtonContainers = `
    ${hdpSharedCSSWidth}
    margin: 0 auto;

    @media ${viewports['xl-and-up-bigger-listing-cards']} {
        display: inline-block;
        margin: 0 16px;
    }
`;

export const TourStatusModuleContainer = styled.div`
    ${hdpSharedCSSWidth}
    margin: 0px auto 16px;

    @media ${viewports['xl-and-up-bigger-listing-cards']} {
        margin: 0px 16px 16px;
        width: 580px;
    }
`;
