// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

// Actions
import AreaActions from 'app/shared/flux/actions/AreaActions';
import ConversationActions from 'app/shared/flux/actions/ConversationActions';
import FetchListingActions from 'app/shared/flux/actions/FetchListingActions';
import { FilterActions_fetchFilterFromUrlAndApi } from 'app/shared/flux/actions/FilterActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import UserActions from 'app/shared/flux/actions/UserActions';

// Components
import ListingNotFoundHdp from 'app/shared/modules/hdp/ListingNotFoundHdp';
import ListingPage from 'app/shared/pages/ListingPage';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import ServerError from 'app/shared/modules/ServerError';
import SkeletonHdp from 'app/shared/modules/skeleton/SkeletonHdp';

// Misc / Utils
import { getCurrentListingIndex } from 'app/shared/utils/listingsReducerUtils';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import { DEFAULT } from 'app/shared/models/Filter';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';

import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import AppActions from 'app/shared/flux/actions/AppActions';
import fetchListingWrapperController from 'app/shared/wrappers/FetchListingWrapper/controller';
import { MapActions_fetchStaticMap } from 'app/shared/flux/actions/MapActions/';
class FetchListingWrapper extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { dispatch, pathname, query, currentListing, isClientSideLoadedPage, byCoordsListings } = this.props;

        if (isClientSideLoadedPage) {
            dispatch(UserActions.hpTours.fetchScheduledToursForUser());
            dispatch(
                FetchListingActions.chooseFetchAction({
                    pathname,
                    query
                })
            ).then((response = {}) => {
                const { listing, originalUrlWhenMakingApiCall } = response;

                if (listing && originalUrlWhenMakingApiCall === decodeURI(window.location.pathname)) {
                    const isMfListing = listing.trusted;
                    if (!listing.building) {
                        const { keyword, maloneLotIdEncoded } = controller.getListingSeoInfo({
                            pathname,
                            searchSlug: 'apartments-for-rent'
                        });
                        dispatch(ListingEngineActions.loadListingSeoLinks(maloneLotIdEncoded, keyword));
                        dispatch(FetchListingActions.fetchListingDetails(listing));
                        dispatch(
                            FetchListingActions.fetchSimilarListings({
                                maloneLotIdEncoded: listing.maloneLotIdEncoded,
                                unit: listing.unit,
                                listingTypes: listing.listingType,
                                limit: 8
                            })
                        );
                    }

                    dispatch(ListingEngineActions.setCurrentListing(listing, byCoordsListings));
                    dispatch(FetchListingActions.fetchListingPermissions(listing.aliasEncoded));
                    dispatch(ConversationActions.fetchRecentMessages({ aliasEncoded: listing.aliasEncoded }));

                    if (isMfListing) {
                        dispatch(FetchListingActions.fetchProviderListingsPortfolio(listing.aliasEncoded));
                    }

                    return true;
                } else {
                    const indexInList = getCurrentListingIndex(byCoordsListings, currentListing);

                    dispatch(ListingEngineActions.setCurrentListingIndex(indexInList));

                    return true;
                }
            });
        } else {
            if (currentListing) {
                dispatch(FetchListingActions.fetchListingPermissions(currentListing.aliasEncoded));
            }

            dispatch(FilterActions_fetchFilterFromUrlAndApi(pathname, query));
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, isClientSideLoadedPage, byCoordsListings, currentListing } = this.props;

        // on SSR list isn't populated
        // if SSR, and byCoords listings get populated, set the index in the list
        if (
            !isClientSideLoadedPage &&
            isEmpty(prevProps.byCoordsListings) &&
            !isEmpty(byCoordsListings) &&
            currentListing &&
            !currentListing.belongsToMultipleUnitBuilding
        ) {
            const indexInList = getCurrentListingIndex(byCoordsListings, currentListing);

            dispatch(ListingEngineActions.setCurrentListingIndex(indexInList));
        }
    }

    componentWillUnmount = () => {
        const { dispatch, isMobile } = this.props;
        dispatch(ListingEngineActions.clearCurrentListing());
        if (!isMobile) {
            dispatch(ListingEngineActions.clearActiveMarkerAndPreviewListing());
        }
    };
    serverRouteWillMount(reactContext) {
        var trace = reactContext.trace;
        const dispatch = reactContext.store.dispatch;
        const pathname = reactContext.state.pathname;
        const query = reactContext.state.query;
        const listingTypes = DEFAULT.rentalTypes;
        const maloneLotIdEncoded = routeUtils.getMaloneLotIdEncodedFromUrl(pathname);
        const unit = routeUtils.getMaloneUnitFromUrl(pathname);
        const { keyword } = fetchListingWrapperController.getListingSeoInfo({
            pathname,
            searchSlug: 'apartments-for-rent'
        });
        let listing;
        trace.startAsyncSpan({
            marker: 'FetchListingWrapper#FetchListingActions#chooseFetchAction'
        });

        const getSeoLinks = dispatch(ListingEngineActions.loadListingSeoLinks(maloneLotIdEncoded, keyword)).catch(
            (error) => {
                dispatch(
                    ErrorActions.errorHandler({
                        error,
                        errorLocation: 'component.listingPage.serverRouteWillMount#loadListingSeoLinks',
                        errorClass: 'listingEngineActions'
                    })
                );
            }
        );
        const getListing = dispatch(FetchListingActions.chooseFetchAction({ pathname, query }))
            .then((response) => {
                // todo: move to an action
                listing = response.listing;

                if (listing) {
                    dispatch(UserActions.hpTours.fetchScheduledToursForUser());
                }

                if (listing) {
                    if (listing.aliasEncoded && listing.active) {
                        dispatch(AppActions.sendEventToApi(constants.LISTING_VIEWED, listing.aliasEncoded));
                    } else if (listing.aliasEncoded && !listing.active) {
                        dispatch(AppActions.sendEventToApi(constants.EXPIRED_LISTING_VIEWED, listing.aliasEncoded));
                    }

                    if (listing.areas && listing.areas.breadcrumbs && listing.areas.breadcrumbs.length > 0) {
                        let areaToLoad = listing.areas.breadcrumbs[1] || listing.areas.breadcrumbs[0];
                        // This is where we get area information
                        return dispatch(AreaActions.getAndSetAreaByResourceId(areaToLoad.resourceId));
                    } else {
                        return response;
                    }
                } else if ((response.redirect && response.to) || response.changeHttpStatus) {
                    return response;
                } else {
                    dispatch(FetchListingActions.fetchListingFail());
                    dispatch(ErrorActions.sendToSplunk(response, 'No listing in FetchListingWrapper'));

                    throw new Error(
                        `Could not get listing from FetchListing for pathname=${pathname} query=${JSON.stringify(
                            query
                        )}`
                    );
                }
            })
            .then((response) => {
                const isDesktop = reactContext.store.getState().app.device.deviceType !== 'mobile';
                if (listing && isDesktop) {
                    return dispatch(MapActions_fetchStaticMap({ area: response, query }));
                } else {
                    return response;
                }
            })
            .then((response) => {
                trace.finishAsyncSpan({
                    marker: 'FetchListingWrapper#FetchListingActions#chooseFetchAction'
                });

                if (listing) {
                    return dispatch(ListingEngineActions.setCurrentListing(listing));
                } else {
                    return response;
                }
            })
            .then((response) => {
                if (listing) {
                    return dispatch(
                        ConversationActions.fetchRecentMessages({
                            aliasEncoded: listing.aliasEncoded
                        })
                    );
                } else {
                    return response;
                }
            })
            .then((response) => {
                if (listing) {
                    const isMfListing = listing.trusted;

                    if (isMfListing) {
                        return dispatch(FetchListingActions.fetchProviderListingsPortfolio(listing.aliasEncoded));
                    }
                    // return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                dispatch(
                    ErrorActions.errorHandler({
                        error,
                        errorLocation: 'component.fetchListingWrapper.serverRouteWillmount#chooseFetchAction',
                        errorClass: 'fetchListingActions',
                        criticalType: 'dataError'
                    })
                );

                return { changeHttpStatus: 500 };
            });

        const getSimilarListings = dispatch(
            FetchListingActions.fetchSimilarListings({
                maloneLotIdEncoded,
                unit,
                listingTypes: Object.keys(listingTypes).join(','),
                limit: 8
            })
        ).catch((error) => {
            dispatch(
                ErrorActions.errorHandler({
                    error,
                    errorLocation: 'component.fetchListingWrapper.serverRouteWillMount#fetchSimilarListings',
                    errorClass: 'fetchListingActions'
                })
            );
        });

        return [getListing, getSeoLinks, getSimilarListings];
    }
    // byCoords call determined the listing was a building (based on filter)
    belongsToBuildingBasedOnFilter = () => {
        const { currentListing, byCoordsListings } = this.props;

        if (!byCoordsListings.length) {
            // short circuit logic (and warning) until byCoordsListings.listingGroups.byCoords is set
            return false;
        }

        const matchedListingInList = byCoordsListings.filter((summary) => {
            return summary.maloneLotIdEncoded === currentListing.maloneLotIdEncoded;
        });

        if (matchedListingInList.length === 0) {
            return false;
        } else {
            return matchedListingInList[0].building;
        }
    };

    getBackUrl = () => {
        const { currentListing, dispatch } = this.props;
        const belongsToMultipleUnitBuilding = currentListing && currentListing.belongsToMultipleUnitBuilding;

        // byCoords and byMaloneLotId agree that the unit comes from a building AND we aren't on a building page
        if (belongsToMultipleUnitBuilding && !currentListing.building && this.belongsToBuildingBasedOnFilter()) {
            return dispatch(
                RouteActions.buildListingPathWithMap({
                    listingUri: currentListing.uriBuilding,
                    keepMapLocation: true
                })
            );
        } else {
            return dispatch(RouteActions.getAreaPathWithMapData());
        }
    };

    handlePopupClose = () => {
        const backUrl = this.getBackUrl();
        window.router.transitionTo(backUrl);
    };

    renderElement = () => {
        const { fetchListingFailed, currentListing, sidebarId, similarListings, indexInList, byCoordsListings } =
            this.props;
        const listOfHdpUrls = byCoordsListings.map((summary) => summary.uriV2);

        if (fetchListingFailed === constants.LISTING_NOT_FOUND) {
            return <ListingNotFoundHdp listings={similarListings} />;
        } else if (fetchListingFailed && !currentListing) {
            return <ServerError listings={similarListings} />;
        } else if (!currentListing) {
            return <SkeletonHdp indexInList={indexInList} listOfHdpUrls={listOfHdpUrls} />;
        } else {
            return <ListingPage sidebarId={sidebarId} />;
        }
    };

    render() {
        return this.renderElement();
    }
}

const mapStateToProps = (state, ownProps) => ({
    byCoordsListings: state.listings.listingGroups.byCoords,
    currentListing: state.currentListingDetails.currentListing,
    fetchListingFailed: state.fetchListing.fetchListingFailed,
    indexInList: state.currentListingDetails.indexInList,
    isClientSideLoadedPage: state.app.isClientSideLoadedPage,
    isMobile: state.app.device.screenWidth === 'sm',
    pathname: ownProps.location.pathname,
    query: queryUtils.parse(ownProps.location.search)
});

export default withRouter(connect(mapStateToProps)(FetchListingWrapper));
