// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { trackPayPerClick } from 'app/client/tracking';
import { analyticsEvent } from 'app/client/universal-analytics';
import AppActions from 'app/shared/flux/actions/AppActions';
import Linker from 'app/shared/modules/Linker';
import { listingUtils_shouldShowPpcLink } from 'app/shared/utils/listingUtils';
import 'app/shared/modules/hdp/PpcLink.scss';
import { TrackBuildingPayPerLinkClick, TrackLowerPpcClick, TrackUpperPpcClick } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

class PpcLink extends React.Component {
    static propTypes = {
        device: PropTypes.object,
        listing: PropTypes.object,
        ppcLocation: PropTypes.oneOf(['address', 'hdp', 'summary'])
    };

    constructor(props) {
        super(props);
        const { device, listing, ppcLocation } = this.props;
        const details = listing.details || {};
        const shouldShowPpcLink = listingUtils_shouldShowPpcLink(details, device);

        // PpcLink appears 2x on HDPs, below address and below floorplans.
        // Check 'ppcLocation' so that a GA event isn't fired twice.
        if (shouldShowPpcLink && ppcLocation === 'address') {
            this.props.dispatch(analyticsEvent(gaEvents.PPC_LINK_DISPLAYED));
        }
    }

    handlePpcClick = yieldCallback(() => {
        this.props.dispatch(analyticsEvent(gaEvents.PPC_LINK_CLICKED));
        this.props.dispatch(AppActions.sendEventToApi('companyWebsiteVisited'));

        if (this.props.ppcLocation === 'address') {
            this.props.dispatch(analyticsEvent(gaEvents.HDP_PPC_CLICKED_UPPER, { newLaneEvent: TrackUpperPpcClick() }));
            this.props.dispatch(analyticsEvent({ newLaneEvent: TrackBuildingPayPerLinkClick({ triggerObject: 'home_details_component|summary' }) }));
        } else if (this.props.ppcLocation === 'hdp') {
            this.props.dispatch(analyticsEvent(gaEvents.HDP_PPC_CLICKED_LOWER, { newLaneEvent: TrackLowerPpcClick() }));
            this.props.dispatch(analyticsEvent({ newLaneEvent: TrackBuildingPayPerLinkClick({ triggerObject: 'home_details_component|property_details' }) }));
        }
        trackPayPerClick(this.props.listing);
    });

    render() {
        const { device, linkText, listing } = this.props;
        const details = listing.details || {};
        const shouldShowPpcLink = listingUtils_shouldShowPpcLink(details, device);
        let website;

        // Disable PPC links on mobile devices.
        if (shouldShowPpcLink) {
            website = details.webSiteOriginalDomain
                ? 'Visit ' + details.webSiteOriginalDomain
                : "Visit Property's Website";
            var isUglyDomain =
                website.split('.').length > 3 || includes(website, 'prospectportal') || includes(website, 'reslisting');

            if (isUglyDomain) {
                website = "Visit Property's Website";
            }

            // the double <span> around the Linker is necessary for hyperlink elipses truncation
            return (
                <div className="PpcLink">
                    <span className="PpcLink-link">
                        <span className="PpcLink-link-target">
                            <Linker
                                to={details.webSite}
                                onClick={this.handlePpcClick}
                                rel="nofollow"
                                target="_blank"
                                removeNoOpener
                                removeNoReferrer
                            >
                                {linkText || website}
                            </Linker>
                        </span>
                    </span>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    device: state.app.device
});

export default connect(mapStateToProps)(PpcLink);
