// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import IconArrowLeftDark from 'images/icons/arrow-left-dark-grey.svg';
import IconArrowRightDark from 'images/icons/arrow-right-dark-grey.svg';
import { yieldCallback } from '@zillow/yield-callback';

class PhotoCarousel extends Component {
    static propTypes = {
        animateChange: PropTypes.bool,
        backgroundColor: PropTypes.string,
        className: PropTypes.string,
        defaultIndex: PropTypes.number,
        ignoreKeypress: PropTypes.bool,
        itemCountOffset: PropTypes.number,
        itemCountPosition: PropTypes.string,
        loopable: PropTypes.bool,
        onChange: PropTypes.func,
        showItemCount: PropTypes.bool
    };

    static defaultProps = {
        animateChange: true,
        backgroundColor: '#000',
        ignoreKeypress: false,
        itemCountOffset: 0,
        itemCountPosition: 'bottom-right',
        loopable: false,
        onChange: () => { },
        showItemCount: true
    };

    constructor(props) {
        super(props);

        this.state = {
            useTransition: false,
            positionX: 0,
            index: this.sanitizeIndex(props.defaultIndex, 0, props.children)
        };
    }

    componentDidMount() {
        if (this.props.defaultIndex) {
            this.goToIndex(this.props.defaultIndex);
        }

        document.addEventListener('keydown', this.handleKeyboardNav);
        document.removeEventListener('mousemove', this.handleSwiping);
        document.removeEventListener('touchmove', this.handleSwiping);
        document.removeEventListener('mouseup', this.handleEnd);
        document.removeEventListener('touchend', this.handleEnd);
    }

    componentWillReceiveProps(nextProps) {
        const sanitizedIndex = this.sanitizeIndex(nextProps.defaultIndex, this.state.index, nextProps.children);
        if (sanitizedIndex !== this.state.index) {
            this.setState(() => {
                return {
                    index: sanitizedIndex
                };
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyboardNav);
    }

    sanitizeIndex = (index, defaultIndex, children) => {
        if (index !== 0 && !index) {
            return defaultIndex;
        }

        const childComponents = React.Children.toArray(children);
        if (index < 0 || index > childComponents.length) {
            return 0;
        }

        return index;
    };

    handleCenterClick = (e, onClick) => {
        this.endX = controller.touchX(e);
        e.stopPropagation();
        e.preventDefault();
        const movedX = this.endX - this.startX;
        if (Math.abs(movedX) < 2 && onClick) {
            onClick(e);
        }
    };

    getContainerWidth = () => {
        if (this.container) {
            return this.container.getBoundingClientRect().width;
        }
    };

    handleSwiping = (e) => {
        const { index } = this.state;
        const children = React.Children.toArray(this.props.children);
        const maxChildIndex = children.length - 1;
        const isFirst = index === 0;
        const isLast = index === maxChildIndex;

        const containerWidth = this.getContainerWidth();
        const touchX = controller.touchX(e);
        const touchY = controller.touchY(e);
        const movedX = touchX - this.startX;
        const overscrollMultiplier = 0.2;
        let positionX;

        const deltaX = this.prevX - touchX;
        const deltaY = this.prevY - touchY;
        const isVerticalSwipe = Math.abs(deltaY) >= Math.abs(deltaX);
        this.prevX = touchX;
        this.prevY = touchY;
        if (isVerticalSwipe) {
            return false;
        }

        // no need for e.preventDefault() - https://www.chromestatus.com/features/5093566007214080
        e.stopPropagation();

        if (isFirst) {
            const maxOverscrollRightX = -1 * (containerWidth * (1 + overscrollMultiplier));
            const maxOverscrollLeftX = containerWidth * overscrollMultiplier;
            if (movedX > maxOverscrollLeftX) {
                positionX = maxOverscrollLeftX;
            } else if (movedX < maxOverscrollRightX) {
                positionX = maxOverscrollRightX;
            } else {
                positionX = movedX;
            }
        } else if (isLast) {
            const maxOverscrollRightX = -1 * (containerWidth * overscrollMultiplier);
            const maxOverscrollLeftX = containerWidth * (1 + overscrollMultiplier);
            if (movedX > maxOverscrollLeftX) {
                positionX = maxOverscrollLeftX;
            } else if (movedX < maxOverscrollRightX) {
                positionX = maxOverscrollRightX;
            } else {
                positionX = movedX;
            }
        } else {
            const maxOverscrollX = containerWidth * (1 + overscrollMultiplier);
            const isMaxedOut = Math.abs(movedX) > maxOverscrollX;
            positionX = isMaxedOut ? Math.sign(movedX) * maxOverscrollX : movedX;
        }

        this.setState({
            useTransition: false,
            positionX
        });
    };

    goToIndex = yieldCallback((index, animateChange) => {
        const { onChange, loopable } = this.props;
        const children = React.Children.toArray(this.props.children);
        const maxIndex = this.props.maxIndex ? this.props.maxIndex : children.length - 1;

        if (index < 0) {
            index = loopable ? maxIndex : 0;
        } else if (index > maxIndex) {
            index = loopable ? 0 : maxIndex;
        }
        this.setState({
            useTransition: animateChange && true,
            positionX: 0,
            index
        });
        onChange(index);
    });

    handleClickPrev = (e) => {
        const { index } = this.state;
        const { animateChange } = this.props;
        e.stopPropagation();
        this.goToIndex(index - 1, animateChange);
    };

    handleClickNext = (e) => {
        const { index } = this.state;
        const { animateChange } = this.props;
        e.stopPropagation();
        this.goToIndex(index + 1, animateChange);
    };

    handleEnd = (e) => {
        this.endX = controller.touchX(e);
        const swipeLimitX = 50;
        const movedX = this.endX - this.startX;
        const prev = movedX > swipeLimitX;
        const next = movedX < -swipeLimitX;
        if (prev) {
            this.goToIndex(this.state.index - 1, true);
        } else if (next) {
            this.goToIndex(this.state.index + 1, true);
        } else {
            this.setState({
                useTransition: true,
                positionX: 0
            });
        }
        document.removeEventListener('mousemove', this.handleSwiping);
        document.removeEventListener('touchmove', this.handleSwiping);
        document.removeEventListener('mouseup', this.handleEnd);
        document.removeEventListener('touchend', this.handleEnd);
    };

    handleStart = (e) => {
        this.startX = this.prevX = controller.touchX(e);
        this.startY = this.prevY = controller.touchY(e);
        document.addEventListener('mousemove', this.handleSwiping);
        document.addEventListener('touchmove', this.handleSwiping);
        document.addEventListener('mouseup', this.handleEnd);
        document.addEventListener('touchend', this.handleEnd);
    };

    handleKeyboardNav = (e) => {
        if (this.props.ignoreKeypress) {
            return;
        }

        if (e.keyCode === constants.LEFT_ARROW_KEYCODE) {
            this.goToIndex(this.state.index - 1, this.props.animateChange);
        } else if (e.keyCode === constants.RIGHT_ARROW_KEYCODE) {
            this.goToIndex(this.state.index + 1, this.props.animateChange);
        }
    };

    getStyles = () => {
        return {
            maxHeight: '100%',
            maxWidth: '100%',
            height: '330',
            width: '500'
        };
    };

    render() {
        const { index, positionX, useTransition } = this.state;
        const { className, itemCountOffset, itemCountPosition, backgroundColor } = this.props;
        const children = React.Children.toArray(this.props.children);
        const maxIndex = this.props.maxIndex ? this.props.maxIndex : children.length - 1;
        const isFirst = index === 0;
        const isLast = index === maxIndex;
        let realX = 0;
        const containerWidth = this.getContainerWidth();
        if (containerWidth) {
            realX = positionX - index * containerWidth;
        }
        const maxDisplayItemCount = maxIndex + 1 + itemCountOffset;
        const currentDisplayIndex = index + 1;
        const displayItemCount = `${currentDisplayIndex}/${maxDisplayItemCount}`;
        const itemStyle = {
            width: containerWidth ? controller.getPx(containerWidth) : '100%'
        };
        const trackStyle = {
            transform: `translate3d(${realX}px, 0px, 0px)`,
            width: controller.getPx(containerWidth * children.length)
        };

        // handle case where width 100% is greater than image width...
        // set container width to image[0] width? set a standard width?
        return (
            <div className={cx('PhotoCarousel', className)}>
                <div
                    className="PhotoCarousel-container"
                    onMouseDown={this.handleStart}
                    onTouchStart={this.handleStart}
                    ref={(el) => (this.container = el)}
                    style={{ backgroundColor }}
                >
                    <ul
                        className={cx('PhotoCarousel-track', {
                            'PhotoCarousel-track-transition': useTransition
                        })}
                        style={trackStyle}
                    >
                        {children.map((child, i) => {
                            return (
                                <li
                                    className={cx('PhotoCarousel-item-wrapper', {
                                        'PhotoCarousel-item-hidden': index !== i
                                    })}
                                    style={itemStyle}
                                    key={child.key}
                                >
                                    {React.cloneElement(child, {
                                        className: cx('PhotoCarousel-item', child.props.className),
                                        onClick: (e) => this.handleCenterClick(e, child.props.onClick),
                                        style: this.getStyles()
                                    })}
                                </li>
                            );
                        })}
                    </ul>
                    {this.props.showItemCount && currentDisplayIndex <= maxDisplayItemCount && (
                        <div
                            className={cx('PhotoCarousel-item-count', {
                                'PhotoCarousel-item-count-bottom-right': itemCountPosition === 'bottom-right',
                                'PhotoCarousel-item-count-top-right': itemCountPosition === 'top-right',
                                'PhotoCarousel-item-count-hide': itemCountPosition === 'none'
                            })}
                        >
                            <div className="PhotoCarousel-item-count-badge">{displayItemCount}</div>
                        </div>
                    )}
                    {children.length > 1 && (
                        <div className="PhotoCarousel-arrow-controls">
                            <div className="PhotoCarousel-prev-target" onClick={this.handleClickPrev}>
                                <button
                                    aria-label="Previous image"
                                    className={cx('PhotoCarousel-arrow', {
                                        'PhotoCarousel-arrow-disabled': isFirst
                                    })}
                                    onClick={this.handleClickPrev}
                                >

                                    <img src={IconArrowLeftDark} width="16px" height="16px" />
                                </button>
                            </div>
                            <div className="PhotoCarousel-next-target" onClick={this.handleClickNext}>
                                <button
                                    aria-label="Next image"
                                    className={cx('PhotoCarousel-arrow', 'PhotoCarousel-arrow-right', {
                                        'PhotoCarousel-arrow-disabled': isLast
                                    })}
                                    onClick={this.handleClickNext}
                                >
                                    <img src={IconArrowRightDark} width="16px" height="16px" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default PhotoCarousel;
