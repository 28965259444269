// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase, dot-notation, max-len */
// Tracking scripts for HotPads marketing campaigns.
import constants from 'app/shared/constants/ConstantsBundle';

const logger = getLogger('trackingUtils');

const trackingUtils = {
    rafAsync() {
        return new Promise((resolve) => {
            requestAnimationFrame(resolve); //faster than set time out
        });
    },

    //utility function to wait for OneTrust to be initialized
    checkOptanon() {
        if (typeof Optanon === 'undefined') {
            return trackingUtils.rafAsync().then(() => trackingUtils.checkOptanon());
        } else {
            return Promise.resolve(true);
        }
    },

    indexAdWrapper: {
        appendScript() {
            return (dispatch) => {
                try {
                    const addIdAttribute = () => {
                        const targetElement = document.querySelector('script[src*="182276-265378620286299.js"]');
                        targetElement.setAttribute('id', 'indexTracker');
                    };

                    trackingUtils.checkOptanon().then(() => {
                        //eslint-disable-next-line no-undef
                        Optanon.InsertScript(
                            '//js-sec.indexww.com/ht/p/182276-265378620286299.js',
                            'head',
                            addIdAttribute,
                            dispatch({
                                type: constants.SET_APP_STORE_BOOL,
                                payload: {
                                    name: 'indexAdWrapperInit',
                                    bool: true
                                }
                            }),
                            4
                        );
                    });
                } catch (err) {
                    logger.error(err.stack, 'Error loading indexAdWrapper in appendScriptExp');
                }
            };
        },
        discardScript() {
            return () => {
                const targetElement = document.getElementById('indexTracker');

                if (targetElement) {
                    targetElement.remove();
                }
            };
        }
    },
    goog: {
        appendScript() {
            // Need to append Google Ad Services tracker if it isn't already on the page.
            if (!document.getElementById('google-ad-services')) {
                try {
                    trackingUtils.checkOptanon().then(() => {
                        //eslint-disable-next-line no-undef
                        Optanon.InsertScript(
                            '//www.googleadservices.com/pagead/conversion_async.js',
                            'head',
                            null,
                            null,
                            4
                        );
                    });
                } catch (err) {
                    logger.error(err.stack, 'Error loading google ad services');
                }
            }
        }
    },
    removeElementById(id) {
        const elem = document.getElementById(id);

        if (elem) {
            return elem.parentNode.removeChild(elem);
        }
    }
};

export default trackingUtils;
