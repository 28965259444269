// @ts-nocheck
/* eslint-enable */
// Misc / Utils
import ExploreWrapperController from 'app/shared/wrappers/ExploreWrapper/controller';
import numberUtils from 'app/shared/utils/numberUtils';
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';

const getAptsForRentFaqTemplate = ({ area, facts, slug, totalListings }) => {
    // Area related details
    const resourceId = area.resourceId;
    const fullNameWithState = area.fullName;
    const name = area.name;
    const uri = `https://hotpads.com/${resourceId}/${slug}`;

    // Facts related details
    const NATIONAL_AVG_RENT = 1469; // Hardcoded. Source: Josh Smith, HotPads economist
    const medianRent = numberUtils.asCurrency(facts.medianRent);
    const aboveOrBelow = facts.medianRent > NATIONAL_AVG_RENT ? 'above' : 'below';
    const numListingsForRent = numberUtils.compact(totalListings);
    const diffFromLastMonth = facts.diffFromLastMonth ? `${facts.diffFromLastMonth.toFixed(2)}%` : '0%';
    const formattedNationalAvgRent = numberUtils.asCurrency(NATIONAL_AVG_RENT);

    return {
        url: uri,
        questions: [
            {
                question: `How expensive is it to rent an apartment in ${fullNameWithState}?`,
                answerType: 'answer',
                answer: `The median ${fullNameWithState} rent is ${medianRent} which is ${aboveOrBelow} the national median rent of ${formattedNationalAvgRent}. In addition to the rent cost, you need to also account for costs of basic utilities consisting of water, garbage, electric and natural gas. Check with your local ${name} utilities for estimates.`
            },
            {
                question: `How many Apartments are available now in ${fullNameWithState}?`,
                answerType: 'answer',
                answer: `There are currently ${numListingsForRent} apartments for rent on the Zillow platform in ${name}, which fluctuated ${diffFromLastMonth} over the past 30-days. `
            },
            {
                question: `How does HotPads make it easier to find an apartment in ${fullNameWithState}?`,
                answerType: 'answer',
                answer: `HotPads amenity filters and keyword searches allow you to target exactly what you're looking for in the ${fullNameWithState} area. We surface the largest marketplace of apartment rentals to search through, so you can feel confident you'll find the best available rental in ${name}. When new apartment listings come on-market, our recommended searches will notify you in real-time, giving you an advantage to contact landlords and property managers as fast as possible.`
            }
        ]
    };
};

const getHousesForRentFaqTemplate = ({ area, facts, slug, totalListings }) => {
    // Area related details
    const resourceId = area.resourceId;
    const fullNameWithState = area.fullName;
    const name = area.name;
    const uri = `https://hotpads.com/${resourceId}/${slug}`;

    // Facts
    const NATIONAL_AVG_RENT = 1469; // Hardcoded. Source: Josh Clark, Senior Economist @ Zillow Group
    const medianRent = numberUtils.asCurrency(facts.medianRent);
    const medianAvgDiff = numberUtils.asCurrency(Math.abs(NATIONAL_AVG_RENT - facts.medianRent));
    const aboveOrBelow = facts.medianRent > NATIONAL_AVG_RENT ? 'above' : 'below';
    const medianAge = numberUtils.compact(facts.medianAge);
    const numListingsForRent = numberUtils.compact(totalListings);
    const diffFromLastMonth = `${facts.diffFromLastMonth.toFixed(2)}%`;
    const medianHouseholdIncome = numberUtils.asCurrency(facts.medianHouseholdIncome);
    const population = numberUtils.fullNumber(facts.population);
    const percentRenters = numberUtils.asPercent(facts.percentRenters);
    const percentOwners = numberUtils.asPercent(100 - facts.percentRenters);
    const formattedNationalAvgRent = numberUtils.asCurrency(NATIONAL_AVG_RENT);

    return {
        url: uri,
        questions: [
            {
                question: `How difficult is it to rent a house in ${fullNameWithState}`,
                answerType: 'answer',
                answer: `There are currently ${numListingsForRent} houses available for rent which fluctuated ${diffFromLastMonth} over the last 30-day period for ${name}.`
            },
            {
                question: `What are the rental costs for houses in ${fullNameWithState}?`,
                answerType: 'answer',
                answer: `The median rent in ${name} is ${medianRent}. That's ${medianAvgDiff} ${aboveOrBelow} the national average rent of ${formattedNationalAvgRent}.`
            },
            {
                question: `How does HotPads make it easier to find a rental home in ${fullNameWithState}?`,
                answerType: 'answer',
                answer: `Our in-depth filtering with keyword searches allows you to target exactly what you're looking for in and near the ${fullNameWithState} area. We surface the largest marketplace of home rentals to search through, so you can feel confident you'll find the best of available houses for rent in ${name}. When new house listings come on-market, our recommended searches will notify you in real-time, giving you an advantage to contact landlords and property managers as fast as possible.`
            },
            {
                question: `What are some general demographics of ${fullNameWithState}?`,
                answerType: 'unorderedList',
                listHeading: null,
                answer: [
                    `Median Age: ${medianAge}`,
                    `Median Household Income: ${medianHouseholdIncome}`,
                    `Total Population: ${population}`,
                    `Percent renters: ${percentRenters}`,
                    `Percent homeowners: ${percentOwners}`
                ]
            }
        ]
    };
};

const getPaidHDPFaqTemplate = ({ listing }) => {
    const isHouse = listing.propertyType === 'house';
    const amenities = listing.amenities;
    const formattedBuildingName = listing.displayName;
    const petPolicy = (amenities && amenities.condensedAmenities && amenities.condensedAmenities.pets) || null;
    const formattedPetPolicy = petPolicy && petPolicy.toLowerCase();
    const laundryAmenity = (amenities && amenities.condensedAmenities && amenities.condensedAmenities.laundry) || null;
    const formattedLaundryAmenity = laundryAmenity && laundryAmenity.toLowerCase();
    const formattedParkingAmenity =
        (amenities && amenities.condensedAmenities && amenities.condensedAmenities.parking) || null;
    const formattedCity = listing.address.city;
    const formattedNeighborhood = listing.address.neighborhood;
    const formattedSchoolDistrict = listing.areas.districts.length && listing.areas.districts[0].fullName;
    const formattedLastUpdated = listing.history.lastUpdated.ago;

    const availableUnits = listing.modelsAndPricing;
    const formattedAvailableUnits = availableUnits.map((unit) => {
        const formattedBedString =
            unit.numBeds === 0 ? 'studio apartment' : `${numberUtils.asWord(unit.numBeds)}-bedroom apartment`;

        // Singular
        if (unit.quantity === 1) {
            return `There is ${unit.quantity} ${formattedBedString} available${unit.lowPrice ? ` for ${numberUtils.asCurrency(unit.lowPrice)}` : '.'
                }`;
        }

        // Plural
        return `There are ${unit.quantity} ${formattedBedString}s available${unit.lowPrice ? ` starting at ${numberUtils.asCurrency(unit.lowPrice)}` : '.'
            }`;
    });

    const questionSet = [
        {
            question: `What is ${isHouse ? "this home's" : "the apartment building's"} pet policy?`,
            answerType: 'answer',
            answer: petPolicy
                ? `There are ${formattedPetPolicy} at ${formattedBuildingName}. Please contact the property manager for up to date pet policy and fee information.`
                : `Please contact the property manager at ${formattedBuildingName} for up to date pet policy information.`
        },
        {
            question: `Is there laundry in the ${isHouse ? 'house' : 'building'}?`,
            answerType: 'answer',
            answer:
                laundryAmenity && laundryAmenity !== 'none'
                    ? `This building offers ${formattedLaundryAmenity} laundry.`
                    : laundryAmenity === 'none'
                        ? `No, there is no laundry offered at ${formattedBuildingName}.`
                        : `Please contact ${formattedBuildingName} for information regarding laundry amenities.`
        },
        {
            question: `Does the ${isHouse ? 'home' : 'building'} offer parking?`,
            answerType: 'answer',
            answer: `${formattedParkingAmenity
                    ? `Yes, ${formattedBuildingName} provides parking.`
                    : `For up to date tenant parking information, please contact ${formattedBuildingName}.`
                }`
        },
        {
            question: `What ${formattedCity} neighborhood is ${formattedBuildingName} in?`,
            answerType: 'answer',
            answer: `Your new apartment would be located in the ${formattedNeighborhood} neighborhood.`
        },
        {
            question: `What school district is this ${isHouse ? 'home' : 'building'} in?`,
            answerType: 'answer',
            answer: formattedSchoolDistrict
                ? `${formattedBuildingName} is within the ${formattedSchoolDistrict}. Check out the additional Nearby Schools for more boundary details.`
                : `Please check with property manager to determine the school district ${formattedBuildingName} is in.`
        },
        {
            question: `When was this listing last updated?`,
            answerType: 'answer',
            answer: `The property manager updated this rental listing ${formattedLastUpdated}.`
        }
    ];

    if (!isHouse) {
        questionSet.unshift({
            question: `How many apartment units are available and what are their rents?`,
            answerType: 'unorderedList',
            answer: formattedAvailableUnits
        });
    }

    const faqObject = {
        url: listing.uriV2,
        questions: questionSet
    };

    return faqObject;
};

const getPetFriendlyFaqTemplate = ({ area, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;
    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;
    const numHousesForRent = numberUtils.compact(totalListings);
    const isHousesSrp = searchSlugUtils.getPropertyTypeFromSlug(searchSlug) === 'houses';

    return {
        url: uri,
        questions: [
            {
                question: isHousesSrp
                    ? `How many Pet Friendly house rentals are available in ${fullName}?`
                    : `How many Pet Friendly apartments are available in ${fullName}?`,
                answerType: 'answer',
                answer: isHousesSrp
                    ? `Currently there are ${numHousesForRent} available Pet Friendly rental houses for rent.`
                    : `Currently there are ${numHousesForRent} available Pet Friendly apartment units for rent.`
            },
            {
                question: isHousesSrp
                    ? `Is there a premium rent increase for Pet Friendly houses in ${fullName}?`
                    : `Is there a premium rent increase for Pet Friendly apartments in ${fullName}`,
                answerType: 'answer',
                answer: isHousesSrp
                    ? `Typically landlords will require a pet deposit, check out our listings and contact the landlord for up to date pet policy and fee information.`
                    : `Most apartment rentals will charge a pet deposit, check out our listings and contact the property manager for up to date pet policy and fee information.`
            },
            {
                question: `What type of animals are considered "Pet Friendly?"`,
                answerType: 'answer',
                answer: isHousesSrp
                    ? `When we discuss "Pet Friendly" in the context of renting single family homes, we're specifically talking about Cats and Dogs only. Additionally, each rental home will have their own criteria and limits around how many cats or dogs are allowed and their size based on weight. Although a rental house might be labeled as "Pet Friendly," it's best to inquire directly with the property manager or Landlord for their specific pet policies.`
                    : `When we discuss "Pet Friendly" in the context of renting apartments, we're specifically talking about Cats and Dogs only. Additionally, each property will have their own criteria and limits around how many cats or dogs are allowed and their size based on weight. Although an apartment might be labeled as "Pet Friendly," it's best to inquire directly with the property manager for their specific pet policies.`
            },
            {
                question: isHousesSrp
                    ? `How to search for Pet Friendly Rental Homes in ${fullName}?`
                    : `How to search for Pet Friendly Apartments in ${fullName}?`,
                answerType: 'answer',
                answer: isHousesSrp
                    ? `On HotPads we offer the option to select either Cats or Dogs or both within our search filters. This allows users to be more specific in their Pet Friendly home search in ${name}.`
                    : `On HotPads we offer the option to select either Cats or Dogs or both within our search filters. This allows users to be more specific in their Pet Friendly apartment search in ${name}.`
            }
        ]
    };
};

const getAffordableApartmentsFaqTemplate = ({ area, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;
    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;
    const numHousesForRent = numberUtils.compact(totalListings);
    const isHousesSrp = searchSlugUtils.getPropertyTypeFromSlug(searchSlug) === 'houses';

    /**
     * HPWEB-5878: Text references to "cheap" will remain for SEO reasons.
     */
    return {
        url: uri,
        questions: [
            {
                question: isHousesSrp
                    ? `How many cheaper, more affordable Rental Houses are available in ${fullName}?`
                    : `How many cheaper, more affordable apartments are available in ${fullName}?`,
                answerType: 'answer',
                answer: isHousesSrp
                    ? `There are currently ${numHousesForRent} cheaper houses available for rent.`
                    : `There are ${numHousesForRent} cheaper apartment units available for rent.`
            },
            {
                question: isHousesSrp
                    ? `What does "Cheap" mean for rental homes in ${fullName}?`
                    : `What does "Cheap" mean for Apartment rentals in ${fullName}?`,
                answerType: 'answer',
                answer: isHousesSrp
                    ? `Cheap is a relative term to communicate more "affordable" rental and living expenses. Although a rental property might be found within the "Cheap Houses" search, does not mean it is a low-quality rental. These more affordable single family homes are also relative to the other rental home inventory within ${name}. Some areas will be cheaper than others and based on demand, rent will fluctuate.`
                    : `Cheap is a relative term to communicate more "affordable" rental and living expenses. Although a property might be found within the "Cheap Apartments" search, does not mean it is a low-quality rental. These more affordable apartments are also relative to the other apartment inventory within ${name}. Some areas will be cheaper than others and based on demand, rent will fluctuate.`
            },
            {
                question: isHousesSrp
                    ? `How to search for Cheap Houses in ${fullName}?`
                    : `How to search for Cheap Apartments in ${fullName}?`,
                answerType: 'answer',
                answer: isHousesSrp
                    ? `When searching on HotPads for more affordable houses, use the "Sort:" feature next to the "Get alerts" button to sort single family homes by Lowest Price for ${name}. Additionally, set a max price for your search and save the alert to be notified in real-time when "cheaper" houses come on the market.`
                    : `When searching on HotPads for more affordable apartments, use the "Sort:" feature next to the "Get alerts" button to sort apartments by Lowest Price for ${name}. Additionally, set a max price for your search and save the alert to be notified in real-time when "cheaper" apartments come on the market.`
            },
            {
                question: isHousesSrp
                    ? `What are some additional tips for securing a "cheaper" rental home?`
                    : `What are some additional tips for securing a "cheaper" apartment?`,
                answerType: 'unorderedList',
                listHeader: isHousesSrp
                    ? 'Single family homeowners or property managers can be easier to negotiate rental terms with than larger multi-tenant buildings. Here are a couple things that you can discuss to potentially drop monthly rental costs:'
                    : "Although a larger multi-tenant building is less likely to negotiate rental terms there are a few things that don't hurt to try, including:",
                answer: isHousesSrp
                    ? [
                        'Offer to sign a longer lease for lowered monthly rent',
                        'Negotiate landscaping or property maintenance for lowered monthly rent',
                        `Educate yourself regarding the housing rental market and market rates in ${name}. Knowing this will help you accurately negotiate fair rent.`
                    ]
                    : [
                        'Offer to sign a longer lease for lowered monthly rent',
                        'Negotiate landscaping or property maintenance for lowered monthly rent',
                        'Ask about becoming an onsite property manager',
                        `Educate yourself regarding the apartment rental market and market rates in ${name}. Knowing this will help you accurately negotiate fair rent.`
                    ]
            }
        ]
    };
};

const getFrboFaqTemplate = ({ area, factsByBeds, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;
    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;
    const numHousesForRent = numberUtils.compact(totalListings);

    const faq = {
        url: uri,
        questions: [
            {
                question: `How many private landlord rentals are available in ${fullName}`,
                answerType: 'answer',
                answer: `There are currently ${numHousesForRent} private landlord ${totalListings !== 1 ? 'rentals' : 'rental'
                    } in the ${name} area.`
            },
            {
                question: `What does FRBO mean?`,
                answerType: 'answer',
                answer: `FRBO is an acronym for "For Rent by Owner." This means that the private landlord acts as the property manager and is the individual that a renter would work with directly for renting their property.`
            },
            {
                question: `How do I search for house, apartment, condo, and townhome private landlord rentals (FRBO)?`,
                answerType: 'answer',
                answer: `Finding single family houses, apartments, condos, and townhomes that are 'For Rent by Owner' is easier on HotPads with our filtering features. Just click into the "All filters" tab and scroll down for "Additional options." Below this select "For rent by owner" to filter all houses and condos that are private landlord rentals.`
            },
            {
                question: `Is there a discount for rental properties that are FRBO?`,
                answerType: 'answer',
                answer: `No, there aren't discounts for rental properties that are rented directly by the owners, however negotiating rental lease terms can sometimes be easier because you are communicating directly with the private landlord and not a real estate management company.`
            }
        ]
    };

    Object.keys(factsByBeds).forEach((keys) => {
        const beds = parseInt(keys);
        const bedroomString = beds ? `${beds} bedroom` : 'studio';

        const averageRentQA = {
            question: `What is the average rent of ${bedroomString} rentals in ${fullName}?`,
            answerType: 'answer',
            answer: `The average rent for ${bedroomString} rentals in ${name} is ${numberUtils.asCurrency(
                factsByBeds[beds]?.avgRent
            )}.`
        };
        faq.questions.push(averageRentQA);
    });

    return faq;
};

const getHousesFrboFaqTemplate = ({ area, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;
    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;
    const numHousesForRent = numberUtils.compact(totalListings);
    return {
        url: uri,
        questions: [
            {
                question: `What does FRBO mean?`,
                answerType: 'answer',
                answer: `FRBO is an acronym for "For Rent by Owner." This means that the private landlord acts as the property manager and is the individual that a renter would work with directly for renting their house.`
            },
            {
                question: `How many private landlord rental houses are available in ${fullName}?`,
                answerType: 'answer',
                answer: `There are currently ${numHousesForRent} private landlord house rentals in the ${name} area.`
            },
            {
                question: `How do I search for private landlord rental (FRBO) houses?`,
                answerType: 'answer',
                answer: `Finding single family houses that are 'For Rent by Owner' is easier on HotPads with our filtering features. Just click into the "All filters" tab and scroll down for "Additional options." Below this select "For rent by owner" to filter for private landlord rentals.`
            },
            {
                question: `Is there a discount for rental houses that are FRBO?`,
                answerType: 'answer',
                answer: `No, there aren't discounts for rental houses that are rented directly by the owners, however negotiating rental lease terms can sometimes be easier because you are communicating directly with the private landlord and not a real estate management company.`
            }
        ]
    };
};

const getApartmentsAndCondosFrboFaqTemplate = ({ area, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;
    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;
    const numHousesForRent = numberUtils.compact(totalListings);

    return {
        url: uri,
        questions: [
            {
                question: `What does FRBO mean?`,
                answerType: 'answer',
                answer: `FRBO is an acronym for "For Rent by Owner." This means that the private landlord acts as the property manager and is the individual that a renter would work with directly for renting their apartment or condo.`
            },
            {
                question: `How many private landlord rental apartments and condos are available in ${fullName}?`,
                answerType: 'answer',
                answer: `There are currently ${numHousesForRent} private landlord apartment and condo rentals in the ${name} area.`
            },
            {
                question: `How do I search for private landlord rental (FRBO) apartments and condos?`,
                answerType: 'answer',
                answer: `Finding apartments and condos that are 'For Rent by Owner' is easier on HotPads with our filtering features. Just click into the "All filters" tab and scroll down for "Additional options." Below this select "For rent by owner" to filter for private landlord rentals.`
            },
            {
                question: `Is there a discount for rental apartments and condos that are FRBO?`,
                answerType: 'answer',
                answer: `No, there aren't discounts for rental apartments and condos that are rented directly by the owners, however negotiating rental lease terms can sometimes be easier because you are communicating directly with the private landlord and not a real estate management company.`
            }
        ]
    };
};

const getTownhomesFrboFaqTemplate = ({ area, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;
    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;
    const numHousesForRent = numberUtils.compact(totalListings);
    return {
        url: uri,
        questions: [
            {
                question: `What does FRBO mean?`,
                answerType: 'answer',
                answer: `FRBO is an acronym for "For Rent by Owner." This means that the private landlord acts as the property manager and is the individual that a renter would work with directly for renting their townhome.`
            },
            {
                question: `How many private landlord rental townhomes are available in ${fullName}?`,
                answerType: 'answer',
                answer: `There are currently ${numHousesForRent} private landlord townhome rentals in the ${name} area.`
            },
            {
                question: `How do I search for private landlord rental (FRBO) townhomes?`,
                answerType: 'answer',
                answer: `Finding townhomes that are 'For Rent by Owner' is easier on HotPads with our filtering features. Just click into the "All filters" tab and scroll down for "Additional options." Below this select "For rent by owner" to filter for private landlord rentals.`
            },
            {
                question: `Is there a discount for rental townhomes that are FRBO?`,
                answerType: 'answer',
                answer: `No, there aren't discounts for rental townhomes that are rented directly by the owners, however negotiating rental lease terms can sometimes be easier because you are communicating directly with the private landlord and not a real estate management company.`
            }
        ]
    };
};

const getRoomsForRentFaqTemplate = ({ area, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;
    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;
    const numHousesForRent = numberUtils.compact(totalListings);

    return {
        url: uri,
        questions: [
            {
                question: `How many rooms available for rent in ${fullName}?`,
                answerType: 'answer',
                answer: `Currently there are ${numHousesForRent} rooms for rent in ${name}`
            },
            {
                question: `How to find rooms for rent in ${fullName}?`,
                answerType: 'answer',
                answer: `Searching Rooms for Rent in the ${name} area is easy on HotPads by filtering for the "Rooms for rent" rental type under the "All filters" tab, and look for the 'Bed' icons on the map. Additionally, depending on your needs, you can also search for "Sublets" and "Furnished" properties to rent within the HotPads filters.`
            },
            {
                question: `Do you still need to sign a lease if you're renting a room?`,
                answerType: 'answer',
                answer: `Lease terms will be determined by the property owner, however it would be smart to educate yourself around renter protections and tenants rights, and ask about signing a lease before renting a room.`
            }
        ]
    };
};

const getBedroomsForRentFaqTemplate = ({ area, factsByBeds, searchSlug, totalListings }) => {
    const { fullName, name, resourceId } = area;

    const beds = parseInt(searchSlug.charAt(0)) || 0;
    const bedroomString = beds ? `${beds} bedroom` : 'studio';
    const numListingsForRent = numberUtils.compact(totalListings);
    const diffFromLastMonth = factsByBeds[beds]?.diffFromLastMonth
        ? `${factsByBeds[beds].diffFromLastMonth.toFixed(2)}%`
        : '0%';

    const uri = `https://hotpads.com/${resourceId}/${searchSlug}`;

    return {
        url: uri,
        questions: [
            {
                question: `What is the average rent of a ${bedroomString} rental in ${fullName}?`,
                answerType: 'answer',
                answer: `The average rent for ${bedroomString} rentals in ${name} is ${numberUtils.asCurrency(
                    factsByBeds[beds]?.avgRent
                )}.`
            },
            {
                question: `How many ${bedroomString} rentals are available now in ${fullName}?`,
                answerType: 'answer',
                answer: `There are currently ${numListingsForRent} rentals for rent on the Zillow platform in ${name}, which fluctuated ${diffFromLastMonth} over the past 30-days. `
            }
        ]
    };
};

export const getFaqSchemaTemplate = ({ searchSlug, data }) => {
    const { area = {}, facts = {}, factsByBeds = {}, listing = {}, totalListings = '' } = data;
    const isPaidHdp = listing && listing.active;

    // Are we on a paid HDP?
    if (isPaidHdp) {
        return {
            data: getPaidHDPFaqTemplate({ listing }).questions,
            props: {
                collapsable: true,
                header: 'Frequently asked questions',
                headerHtmlTag: 'h2',
                active: true
            }
        };
    } else {
        // We're on a SRP
        const SLUG_TO_SRP_TEMPLATE_MAP = {
            'apartments-for-rent': {
                template: getAptsForRentFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'houses-for-rent': {
                template: getHousesForRentFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'for-rent-by-owner': { template: getFrboFaqTemplate, props: { title: 'Frequently asked questions' } },
            'houses-for-rent-by-owner': {
                template: getHousesFrboFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'apartments-and-condos-for-rent-by-owner': {
                template: getApartmentsAndCondosFrboFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'townhomes-for-rent-by-owner': {
                template: getTownhomesFrboFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'pet-friendly-apartments-for-rent': {
                template: getPetFriendlyFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'pet-friendly-houses-for-rent': {
                template: getPetFriendlyFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'affordable-apartments-for-rent': {
                template: getAffordableApartmentsFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'affordable-houses-for-rent': {
                template: getAffordableApartmentsFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'rooms-for-rent': {
                template: getRoomsForRentFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            '1-bedroom-apartments-for-rent': {
                template: getBedroomsForRentFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            '2-bedroom-apartments-for-rent': {
                template: getBedroomsForRentFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            '3-bedroom-apartments-for-rent': {
                template: getBedroomsForRentFaqTemplate,
                props: { title: 'Frequently asked questions' }
            },
            'studio-apartments-for-rent': {
                template: getBedroomsForRentFaqTemplate,
                props: { title: 'Frequently asked questions' }
            }
        };
        const hasFacts = ExploreWrapperController.hasAreaFacts(facts);
        const hasTemplate = SLUG_TO_SRP_TEMPLATE_MAP.hasOwnProperty(searchSlug);

        if (hasFacts && hasTemplate) {
            const getTemplate = SLUG_TO_SRP_TEMPLATE_MAP[searchSlug].template;
            const templateQuestions = getTemplate({
                area,
                facts,
                factsByBeds,
                searchSlug,
                totalListings,
                listing
            }).questions;
            const templateProps = SLUG_TO_SRP_TEMPLATE_MAP[searchSlug].props;

            return {
                data: templateQuestions,
                props: templateProps
            };
        }
    }

    return null;
};
