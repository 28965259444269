// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import Container from 'app/shared/core/Container';
import ListingWrapper from 'app/shared/modules/listing-cards/ListingWrapper';
import Title from 'app/shared/modules/Title';

class NearbyListings extends Component {
    static propTypes = {
        listings: PropTypes.array
    };

    static defaultProps = {
        listings: []
    };

    render() {
        let { listings } = this.props;

        if (isEmpty(listings)) {
            return null;
        }

        return (
            <div className="NearbyListings">
                <Container>
                    <Title size="lg">Nearby Listings</Title>
                </Container>
                {listings.map((listing, i) => {
                    return (
                        <span key={'listingwrapper' + listing.aliasEncoded}>
                            <ListingWrapper
                                key={i + listing.maloneLotIdEncoded}
                                listing={listing}
                                triggerLocation="home_details"
                                triggerObject="property_card"
                            />
                        </span>
                    );
                })}
            </div>
        );
    }
}

export default connect()(NearbyListings);
