// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pageView } from 'app/client/universal-analytics';
import Container from 'app/shared/core/Container';
import formatter from 'app/shared/utils/formatter';
import Head from 'react-helmet';
import Linker from 'app/shared/modules/Linker';
import NearbyListings from 'app/shared/modules/NearbyListings';
import routeUtils from 'app/shared/utils/routeUtils';
import Row from 'app/shared/core/Row';
import Section from 'app/shared/modules/Section';
import './style.scss';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';

const metas = [
    {
        name: 'robots',
        content: 'noindex'
    }
];

class ListingNotFoundHdp extends React.Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        dispatch(pageView());
    }

    render() {
        const { pathname } = this.props;
        const address = routeUtils.parseFailedListingAddress(pathname);
        const maloneUnit = routeUtils.getMaloneUnitFromUrl(pathname);
        let displayAddress = 'This address';

        if (address && maloneUnit) {
            displayAddress = `${formatter.string.firstCaps(address)}, ${formatter.string.firstCaps(maloneUnit)}`;
        } else if (address) {
            displayAddress = formatter.string.firstCaps(address);
        }
        const title = 'Removed Listing | HotPads';

        return (
            <div className="ListingNotFoundHdp">
                <Head title={title} meta={metas} />
                <Container>
                    <Section>
                        <div className="ListingNotFoundHdp-wrapper">
                            <Title size="md">
                                {displayAddress} has no current listing available. Please check back later.
                            </Title>
                            <Row>
                                <Text>
                                    Return to <Linker to="/">HotPads</Linker>
                                </Text>
                            </Row>
                        </div>
                    </Section>
                    <NearbyListings listings={this.props.listings} />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    pathname: ownProps.location.pathname
});

export default withRouter(connect(mapStateToProps)(ListingNotFoundHdp));
