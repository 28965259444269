var devGoogleApiKey = 'AIzaSyBZSwXj1ER-6W1Lf3x-oexXAGFQ878tsaQ';
var productionGoogleApiKey = 'AIzaSyB4yEi5f2oOIwaF-I_KU4mh7-9kwv7G4jo';
var devFbId = '26396991252';
var productionFbId = '60854893843';

var isoConfig = {
    googleApiKey: __DEV__ ? devGoogleApiKey : productionGoogleApiKey,
    googlePublisherId: '7449',
    facebookAppId: '6d73be7cb8eb044e691415c3abffd495',
    fbid: __DEV__ ? devFbId : productionFbId
};

export default isoConfig;
