// @ts-nocheck
/* eslint-enable */
import forEach from 'lodash/forEach';

const controller = {
    getPhotoGridPhotos: ({ listing = {}, observer }) => {
        const { largePhotos = [], floorplans = [] } = listing;

        let photoGridArray = [];
        let floorplanGridArray = [];

        // Generate proper photo object for PhotoGrid library.
        forEach(largePhotos, (photo = {}, idx) => {
            let imgDetails = {
                src: photo.url,
                intersectionObserver: observer,
                galleryidx: idx // must be lowercase
            };

            photoGridArray.push(imgDetails);
        });

        // indices need to continue from the previous photo grid
        // cannot use idx in forEach because some floorplans have multiple largePhotos
        let idx = largePhotos.length;

        forEach(floorplans, (fp = {}) => {
            const { largePhotos: largeFpPhotos = [] } = fp;
            forEach(largeFpPhotos, (photo = {}) => {
                let imgDetails = {
                    src: photo.url,
                    intersectionObserver: observer,
                    galleryidx: idx
                };

                idx += 1;

                floorplanGridArray.push(imgDetails);
            });
        });

        return { photoGridArray, floorplanGridArray };
    }
};

export default controller;
