// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';
import { wrappedPromise } from 'app/client/google/utils';
import './Marker.scss';

const { string, number, shape, object } = PropTypes;
const sizeShape = shape({ width: number, height: number });
const pointShape = shape({ x: number, y: number });
const latLngShape = shape({ lat: number, lng: number });

export class Marker extends React.Component {
    static displayName = 'Marker';

    static propTypes = {
        map: object,
        // developers.google.com/maps/documentation/javascript/reference/3/#LatLngLiteral
        position: latLngShape.isRequired,
        // developers.google.com/maps/documentation/javascript/reference/3/#Icon
        icon: shape({
            anchor: pointShape,
            labelOrigin: pointShape,
            origin: pointShape,
            scaledSize: sizeShape,
            size: sizeShape,
            url: string
        }).isRequired
    };

    static defaultProps = {
        map: null
    };

    componentDidMount() {
        this.markerPromise = wrappedPromise();
        this.renderMarker();
    }

    componentDidUpdate(prevProps) {
        const { map, position } = this.props;

        // TODO: More specific marker checks to ensure whether or not
        // marker properties actually changed. Will prevent blinking
        // of markers when map re-renders.

        if (map !== prevProps.map || position !== prevProps.position) {
            if (this.marker) {
                this.marker.setMap(null);
            }
            this.renderMarker();
        }
    }

    componentWillUnmount() {
        if (this.marker) {
            this.marker.setMap(null);
        }
    }

    renderMarker() {
        let { enableDrag, infoWindowContent, map, position, icon, verticalOffset = -5 } = this.props;

        let { Size, Point } = window.google.maps;

        icon.size = new Size(icon.size.width, icon.size.height);
        icon.scaledSize = new Size(icon.scaledSize.width, icon.scaledSize.height);
        icon.anchor = new Point(icon.anchor.x, icon.anchor.y);

        // developers.google.com/maps/documentation/javascript/reference#MarkerOptions
        this.marker = new window.google.maps.Marker({
            draggable: enableDrag,
            map,
            position,
            icon
        });

        if (infoWindowContent) {
            this.marker.infoWindow = new window.google.maps.InfoWindow({
                content: `<div class="InfoWindowContent">${infoWindowContent}</div>`,
                disableAutoPan: true,
                pixelOffset: new window.google.maps.Size(0, verticalOffset),
                position: this.marker.getPosition()
            });

            this.marker.infoWindow.setZIndex(999);
            this.marker.infoWindow.open(map, this.marker);
        }

        this.markerPromise.resolve(this.marker);
    }

    render() {
        return null;
    }
}

export default Marker;
