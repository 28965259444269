// @ts-nocheck
/* eslint-enable */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import 'app/shared/modules/hdp/galleryV2/GalleryButtonGroupV2.scss';

const GalleryButtonGroupV2 = ({ isMobile, visible, numPhotos, onYieldSelectOption }) => {
    const isShowingGallery = visible !== 'gallery';
    const isShowingMap = isMobile && visible !== 'map';
    const isShowingStreet = (isMobile && visible === 'map') || (!isMobile && visible === 'gallery');

    const handleGalleryClick = useCallback(() => {
        onYieldSelectOption('gallery');
    }, [onYieldSelectOption]);

    const handleMapClick = useCallback(() => {
        onYieldSelectOption('map');
    }, [onYieldSelectOption]);

    const handleStreetClick = useCallback(() => {
        onYieldSelectOption('street');
    }, [onYieldSelectOption]);

    const handleClick = useCallback((e) => {
        e.stopPropagation();
    }, []);

    return (
        <div onClick={handleClick}>
            <div
                className={cx('GalleryButtonGroupV2-layer-options', {
                    'GalleryButtonGroupV2-layer-options-street-map': visible === 'street' || visible === 'map'
                })}
            >
                {isMobile && isShowingGallery && (
                    <button className={cx('GalleryButtonGroupV2-option')} onClick={handleGalleryClick}>
                        See all {numPhotos} photos
                    </button>
                )}
                {isShowingMap && (
                    <button className={cx('GalleryButtonGroupV2-option')} onClick={handleMapClick}>
                        Map
                    </button>
                )}
                {isShowingStreet && (
                    <button className={cx('GalleryButtonGroupV2-option')} onClick={handleStreetClick}>
                        Street view
                    </button>
                )}
                {!isMobile && isShowingGallery && (
                    <button className={cx('GalleryButtonGroupV2-option')} onClick={handleGalleryClick}>
                        See all {numPhotos} photos
                    </button>
                )}
            </div>
        </div>
    );
};

GalleryButtonGroupV2.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    visible: PropTypes.string.isRequired,
    numPhotos: PropTypes.number.isRequired,
    onYieldSelectOption: PropTypes.func.isRequired
};

export default GalleryButtonGroupV2;
