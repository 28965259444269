import React from 'react';
import HamNavLogo from 'images/ham-nav-logo.svg';
import HamNavLogoFull from 'images/ham-nav-logo-with-text.svg';
import HamNavLogoMsg from 'images/ham-nav-logo-msg.svg';
import HamNavLogoFullMsg from 'images/ham-nav-logo-with-text-msg.svg';
import HamburgerNav from 'app/shared/modules/navigation/header/HamburgerNav';

interface HeaderNavMobileLogoProps {
    className?: string;
    onClick?: () => void;
    logoSize?: 'full' | 'compact' | null;
    onCloseHamburgerNav: () => void;
    unreadMsgCount?: number;
}

const HeaderNavMobileLogo: React.FC<HeaderNavMobileLogoProps> = ({
    className = '',
    onClick = () => { },
    logoSize,
    onCloseHamburgerNav,
    unreadMsgCount = 0
}) => {
    const fullLogo = unreadMsgCount > 0 ? HamNavLogoFullMsg : HamNavLogoFull;
    const mobileLogo = unreadMsgCount > 0 ? HamNavLogoMsg : HamNavLogo;

    return (
        <>
            <button className={`${className} HeaderNavMobile-on-click`} onClick={onClick}>
                {logoSize === 'full' ? (
                    <img
                        key="full-logo"
                        className="HeaderNavMobile-logo-img"
                        src={fullLogo}
                        height="24"
                        width="109"
                        alt="HotPads navigation menu"
                        loading="lazy"
                    />
                ) : (
                    <img
                        key="compact-logo"
                        className="HeaderNavMobile-logo-img"
                        src={mobileLogo}
                        height="24"
                        width="44"
                        alt="HotPads navigation menu"
                        loading="lazy"
                    />
                )}
            </button>
            <HamburgerNav onCloseHamburgerNav={onCloseHamburgerNav} />
        </>
    );
};

export default HeaderNavMobileLogo;