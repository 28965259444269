// @ts-nocheck
/* eslint-enable */
import { useYieldCallback } from '@zillow/yield-callback';
const useContactButton = ({ onClick }) => {
    const yieldContactButtonClick = useYieldCallback((buttonLabel = null) => {
        if (buttonLabel) {
            onClick({ customGaLabel: buttonLabel });
        } else {
            onClick();
        }

    });
    const handleContactButtonClick = (e) => {
        const buttonClassName = e.target.classList;
        const shouldSendGaLabel = buttonClassName.length === 1 && buttonClassName.item(0) === 'ContactButtonV2';

        if (shouldSendGaLabel) {
            const buttonLabel = e?.target.attributes.getNamedItem('data-contact-label')?.value;
            yieldContactButtonClick(buttonLabel);
        } else {
            yieldContactButtonClick();
        }
    };

    return [handleContactButtonClick];
};

export default useContactButton;
