// @ts-nocheck
/* eslint-enable */
import includes from 'lodash/includes';
import assign from 'lodash/assign';

import buildingGray from 'images/hp-icons/building-gray.png';
import buildingTeal from 'images/hp-icons/building-teal.png';
import buildingRed from 'images/hp-icons/building-red.png';
import buildingYellow from 'images/hp-icons/building-yellow.png';
import houseGray from 'images/hp-icons/house-gray.png';
import houseTeal from 'images/hp-icons/house-teal.png';
import houseRed from 'images/hp-icons/house-red.png';
import houseYellow from 'images/hp-icons/house-yellow.png';
import roomGray from 'images/hp-icons/room-gray.png';
import roomTeal from 'images/hp-icons/room-teal.png';
import roomRed from 'images/hp-icons/room-red.png';
import roomYellow from 'images/hp-icons/room-yellow.png';

// HPWEB-5163 - no longer distinction between buildings and condos on map

const building = {
    size: { width: 22, height: 36 },
    scaledSize: { width: 22, height: 36 },
    anchor: { x: 22 / 2, y: 36 / 2 }
};
const house = {
    size: { width: 22, height: 26 },
    scaledSize: { width: 22, height: 26 },
    anchor: { x: 22 / 2, y: 26 / 2 }
};
const room = {
    size: { width: 31, height: 25 },
    scaledSize: { width: 31, height: 25 },
    anchor: { x: 31 / 2, y: 25 / 2 }
};

const iconsByIconType = {
    building: {
        red: assign({}, building, { url: buildingRed, color: 'red' }),
        gray: assign({}, building, { url: buildingGray, color: 'gray' }),
        yellow: assign({}, building, { url: buildingYellow, color: 'yellow' }),
        teal: assign({}, building, { url: buildingTeal, color: 'teal' })
    },
    condo: {
        red: assign({}, building, { url: buildingRed, color: 'red' }),
        gray: assign({}, building, { url: buildingGray, color: 'gray' }),
        yellow: assign({}, building, { url: buildingYellow, color: 'yellow' }),
        teal: assign({}, building, { url: buildingTeal, color: 'teal' })
    },
    house: {
        red: assign({}, house, { url: houseRed, color: 'red' }),
        gray: assign({}, house, { url: houseGray, color: 'gray' }),
        yellow: assign({}, house, { url: houseYellow, color: 'yellow' }),
        teal: assign({}, house, { url: houseTeal, color: 'teal' })
    },
    room: {
        red: assign({}, room, { url: roomRed, color: 'red' }),
        gray: assign({}, room, { url: roomGray, color: 'gray' }),
        yellow: assign({}, room, { url: roomYellow, color: 'yellow' }),
        teal: assign({}, room, { url: roomTeal, color: 'teal' })
    }
};

export function iconForListing(listing = {}, isActive, isDefault) {
    let { iconType, listingType, userItemTypes } = listing;

    if (listingType === 'room') {
        iconType = listingType;
    }

    if (isDefault) {
        // Optional override for "default" red icon to display in things
        // mini-map or other areas. Overrides any icon color
        // normally determined by items found in listing.userItemTypes array.
        return iconsByIconType[iconType].red;
    } else if (includes(userItemTypes, 'favorite')) {
        return iconsByIconType[iconType].yellow;
    } else if (isActive) {
        return iconsByIconType[iconType].teal;
    } else if (includes(userItemTypes, 'viewed')) {
        return iconsByIconType[iconType].gray;
    } else {
        return iconsByIconType[iconType].red;
    }
}
