// @ts-nocheck
/* eslint-enable */
const controller = {
    getPx(val) {
        if (isNaN(val)) {
            return 'auto';
        } else {
            return val + 'px';
        }
    },

    touchY(e) {
        if (e.touches && e.touches[0]) {
            return e.touches[0].pageY;
        }
        if (e.changedTouches && e.changedTouches[0]) {
            return e.changedTouches[0].pageY;
        }
        if (e.pageY) {
            return e.pageY;
        }
        if (e.clientY) {
            return (
                e.clientY +
                (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop)
            );
        }
        return null;
    },

    touchX(e) {
        if (e.touches && e.touches[0]) {
            return e.touches[0].pageX;
        }
        if (e.changedTouches && e.changedTouches[0]) {
            return e.changedTouches[0].pageX;
        }
        if (e.pageX) {
            return e.pageX;
        }
        if (e.clientX) {
            return (
                e.clientX +
                (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft)
            );
        }
        return null;
    }
};

export default controller;
