// @ts-nocheck
/* eslint-enable */
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

const controller = {
    hasAreaArticles(articles = []) {
        let hasArticles = false;

        if (!isEmpty(articles)) {
            hasArticles = true;
        }

        return hasArticles;
    },
    hasAreaFacts(facts = {}) {
        let hasFacts = false;

        if (facts.perCapitaIncome) {
            hasFacts = true;
        }

        if (facts.populationDensity) {
            hasFacts = true;
        }

        if (facts.medianHouseholdIncome) {
            hasFacts = true;
        }

        if (facts.population) {
            hasFacts = true;
        }

        if (facts.medianAge) {
            hasFacts = true;
        }

        if (facts.squareMiles) {
            hasFacts = true;
        }

        if (facts.percentRenters) {
            hasFacts = true;
        }

        return hasFacts;
    },
    hasAreaListings(facts) {
        let hasListings = false;
        let propertyTypes = omit(facts.listingCounts, 'DATA_MODEL');

        forEach(propertyTypes, (listingCount) => {
            if (listingCount > 0) {
                hasListings = true;
            }
        });

        return hasListings;
    }
};

export default controller;
