// @ts-nocheck
/* eslint-enable */
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import result from 'lodash/result';

import appUtils from 'app/shared/utils/appUtils';
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';
import states from 'app/shared/utils/states';

const controller = {
    getBreadcrumbs({ area, filter, currentListing }) {
        const searchSlug = filter.search.slug;
        const filterDescription = searchSlugUtils.getDescriptionFromSearchSlug(filter.search.slug);
        let breadcrumbs = [];

        if (currentListing && currentListing.areas && currentListing.areas.breadcrumbs) {
            currentListing.areas.breadcrumbs.forEach((breadcrumbArea) => {
                const breadcrumb = controller.createBreadcrumb({
                    breadcrumbArea,
                    searchSlug
                });
                if (breadcrumb) {
                    breadcrumbs.push(breadcrumb);
                }
            });
        } else if (isArray(area.breadcrumbs)) {
            area.breadcrumbs.forEach((breadcrumbArea) => {
                const breadcrumb = controller.createBreadcrumb({
                    breadcrumbArea,
                    searchSlug
                });
                if (breadcrumb) {
                    breadcrumbs.push(breadcrumb);
                }
            });
        }

        breadcrumbs.forEach((breadcrumb, i, arr) => {
            arr[i] = appUtils.remapPropertyLinks(breadcrumb);

            // only add property type for last breadcrumb
            if (i === breadcrumbs.length - 1) {
                let appendText = filterDescription.match(/.+?(?=for)/) && filterDescription.match(/.+?(?=for)/)[0];

                if (!appendText) {
                    // default for slugs that start with 'for', ex: `for-rent-by-apartments`
                    appendText = 'rentals';
                }

                appendText = appendText.trim();
                arr[i].name += ' ' + appendText;
            }
        });

        return breadcrumbs;
    },
    createBreadcrumb({ breadcrumbArea, searchSlug }) {
        if (breadcrumbArea.type === 'metroarea') {
            return false;
        } else {
            const areaUri = controller.getAreaUri({ breadcrumbArea, searchSlug });
            const linkText = controller.getLinkText(breadcrumbArea);
            return {
                name: linkText,
                uri: areaUri
            };
        }
    },
    getAreaUri({ breadcrumbArea, searchSlug }) {
        if (breadcrumbArea.type === 'state') {
            let stateResourceId = result(find(states, { id: Number(breadcrumbArea.id) }), 'resourceId');
            if (stateResourceId) {
                return '/' + stateResourceId;
            }
        }
        return '/' + breadcrumbArea.resourceId + '/' + searchSlug;
    },
    getLinkText(breadcrumbArea) {
        if (breadcrumbArea.type === 'state') {
            let linkText = result(find(states, { id: Number(breadcrumbArea.id) }), 'abbreviation');
            if (linkText) {
                return linkText;
            }
        }
        return breadcrumbArea.name;
    }
};

export default controller;
