// @ts-nocheck
/* eslint-enable */
import includes from 'lodash/includes';
import map from 'lodash/map';

const schemaUtils = {
    getFormattedReviewerType(reviewerType) {
        if (reviewerType === 'FUTURE_RESIDENT') {
            return 'Future Resident';
        } else if (reviewerType === 'CURRENT_RESIDENT') {
            return 'Current Resident';
        } else if (reviewerType === 'FORMER_RESIDENT') {
            return 'Former Resident';
        } else {
            return '';
        }
    },
    getSchemaType(listingType) {
        switch (listingType) {
            case 'large':
            case 'medium':
            case 'garden':
                return 'ApartmentComplex';
            case 'condo':
            case 'divided':
                return 'Apartment';
            case 'townhouse':
            case 'house':
                return 'SingleFamilyResidence';
            case 'room':
                return 'Room';
            default:
                return 'Place';
        }
    },
    getPlace({ address, geo, type = 'Place', rooms } = {}) {
        const { street, city, state, zip } = address;
        const { lat, lon } = geo;

        const placeSchema = {
            '@type': type,
            address: {
                '@type': 'PostalAddress',
                streetAddress: street,
                addressLocality: city,
                addressRegion: state,
                postalCode: zip,
                addressCountry: 'US'
            },
            geo: {
                '@type': 'GeoCoordinates',
                latitude: lat,
                longitude: lon
            }
        };

        if (includes(['Apartment', 'SingleFamilyResidence'], type) && rooms) {
            placeSchema.numberOfRooms = rooms;
        }

        return placeSchema;
    },
    getReviewSnippet(review) {
        const { created, starLevel, reviewMessage, reviewerType } = review;
        const dateObj = new Date(created);
        const dateISOFormat = dateObj.toISOString();

        return {
            '@type': 'Review',
            author: schemaUtils.getFormattedReviewerType(reviewerType),
            datePublished: dateISOFormat,
            reviewBody: reviewMessage,
            reviewRating: {
                '@type': 'Rating',
                ratingValue: starLevel,
                bestRating: 5,
                worstRating: 1
            }
        };
    },
    getAvailability(listing = {}) {
        let availability = null;
        const includeAvailability =
            listing &&
            listing.address &&
            listing.address.state &&
            includes(['FL', 'NJ', 'DC', 'AZ', 'OR', 'MN', 'MS', 'RI', 'MT', 'VT'], listing.address.state);

        if (includeAvailability) {
            availability = listing && listing.active ? 'LimitedAvailability' : 'OutOfStock';
        }

        return availability;
    },
    getFormattedFaqAnswer(answer, answerType, listHeader = null) {
        const listText = (type, header) => {
            const ListElement = type === 'unorderedList' ? 'ul' : 'ol';

            if (header) {
                return `${header}<${ListElement}>${map(answer, (item) => {
                    return `<li>${item}</li>`;
                })}</${ListElement}>`;
            }

            return `<${ListElement}>${map(answer, (item) => {
                return `<li>${item}</li>`;
            })}</${ListElement}>`;
        };

        return answerType === 'answer'
            ? {
                '@type': 'Answer',
                text: answer
            }
            : {
                '@type': 'Answer',
                text: listText(answerType, listHeader)
            };
    }
};

export default schemaUtils;
